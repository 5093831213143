<template>
    <div class="moral-education-assessment-records">
        <div>
            <expand-filter
                :formData="formData"
                @clickBtn="clickBtn"
                marginBottom="0px"
                borderRadius="0px"
            >
            <template #datePicker="{slotData}">
                <div class="date-picker-wrap">
                    <el-date-picker
                        style="width:330px;"
                        v-model="slotData.value"
                        :clearable="false"
                        type="datetimerange"
                        start-placeholder="请选择开始时间"
                        end-placeholder="请选择结束时间"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        :default-time="['00:00:00', '23:59:59']"
                    >
                    </el-date-picker>
                </div>
            </template>
            <div
                class="filter_Btn"
                slot="other-btn"
            >
                <div class="line"></div>
                <el-button
                    style="margin-left: 0"
                    type="primary"
                    @click="exportStu"
                    >下载详情</el-button
                >
            </div>
            </expand-filter>
            <div class="top_row">
                <div class="score_img">
                    <img
                        :src="exportUrl"
                        alt=""
                        style="width: 14px; height: 14px"
                    />
                </div>
                <div class="all_score">总计：</div>
                <div :class="stuTotal < 0 ? 'add_score' : 'reduce_score'">
                    {{ stuTotal }}
                </div>
            </div>
            <el-table
                ref="popUpTable"
                height="calc(100vh - 262px)"
                :data="dialogTableList"
                v-loading="stuLoadingTable"
                style="width: 100%;"
                :header-cell-style="{
                    background: '#FAFBFC',
                }"
                :row-style="{height: '70px'}"
            >
                <el-table-column
                    prop="behaviorName"
                    label="考核行为"
                    width="120"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    prop="score"
                    label="分数"
                    align="left"
                    width="80"
                >
                    <template slot-scope="{row}">
                        <span :class="row.ruleType === '1' ? 'add' : 'jian'">{{
                            (row.ruleType === "1" ? "-" : "+") + row.score
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="img"
                    label="凭证"
                    width="220"
                >
                    <template slot-scope="{row}">
                        <div style="display: flex;">
                            <div
                                v-for="(item, index) in row.img"
                                :key="index"
                            >
                                <video
                                    v-if="
                                        item.toLowerCase().indexOf('mp4') > -1 ||
                                        item.toLowerCase().indexOf('mov') > -1
                                    "
                                    :class="item ? 'img2' : ''"
                                    disablePictureInPicture
                                    @click="handlePreview(item)"
                                >
                                    <source :src="item" />
                                </video>
                                <img
                                    v-else
                                    :src="item"
                                    alt=""
                                    :class="item ? 'img2' : ''"
                                    @click="handleImg(item)"
                                />
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注"
                    width="120"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    v-if="moralmanageClassManageStuDataCheckPerson"
                    prop="createByName"
                    label="检查人"
                >
                </el-table-column>
                <el-table-column
                    prop="assessDate"
                    label="考核时间"
                    width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="processingResults"
                    label="处理结果"
                    align="center"
                >
                    <template slot-scope="props">
                        <div
                            class="results"
                            v-if="props.row.haveHandle != 0"
                            @click="toogleExpand(props.row)"
                        >
                            {{
                                props.row.processingResultsType == "0"
                                    ? "查看"
                                    : "收起"
                            }}
                            <i
                                :class="
                                    props.row.processingResultsType == '0'
                                        ? 'el-icon-arrow-down'
                                        : 'el-icon-arrow-up'
                                "
                            ></i>
                        </div>
                        <div
                            v-else
                            class="no-results"
                        >
                            无
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    type="expand"
                    width="1"
                >
                    <template slot-scope="props">
                        <div v-if="!props.row.processingLoading">
                            <div
                                class="processing-warper"
                                v-if="props.row.processingList.length > 0"
                            >
                                <div
                                    class="processing-list"
                                    v-for="(item, index) in props.row
                                        .processingList"
                                    :key="index"
                                >
                                    <div class="processing-title">
                                        <p class="processing-des">
                                            {{ item.createByName }}
                                            {{ item.handleTypeName }}
                                            {{ item.createTime?.slice(0, 16) }}
                                        </p>
                                    </div>
                                    <div
                                        class="processing-info"
                                        v-if="item.handleResult"
                                    >
                                        {{ item.handleResult }}
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="empty"
                            >
                                暂无数据~
                            </div>
                        </div>
                        <div
                            v-else
                            v-loading="true"
                        ></div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- mov视频预览 -->
        <dialog-wrapper
            :dialog-obj="dialogMov"
            @handleClose="handleCloseVideo"
            class="dialog-form-video"
        >
            <video
                v-if="videoMovSrc"
                ref="videoElement"
                autoplay
                style="width: 100%; height: 100%"
                controls
                disablePictureInPicture
            >
                <source :src="videoMovSrc" />
            </video>
        </dialog-wrapper>
         <!-- 点击查看图片 -->
         <el-image-viewer
            style="z-index: 9999"
            v-if="showViewer"
            :on-close="handleCloseImg"
            :url-list="[viewPhoto]"
        />
    </div>
</template>
<script>
import {mapState} from "vuex";
import {
    // 业务组件
    DialogWrapper,
} from "common-local";
import {hasPermission, formatTreeData, treeFind} from "@/libs/utils";
import {SManagerEduDeormitoryDataModule} from "@/models/SManagerEduDormitoryAssessmentData.js";
import {getToken} from "@/libs/auth";
import ElSelectTree from "el-select-tree";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {downloadFile} from "@/libs/utils.js";
import ExpandFilter from "@/components/scrollWrapper/Sub/ExpandFilter";
import {SManagerEduMoralStuDataModule} from "@/models/SManagerEduMoralStuData.js";

export default {
    name: "MoralEducationAssessmentRecords",
    props: {
        stuLoadingTable: Boolean,
        basicForm: Object,
    },
    components: {
        DialogWrapper,
        ExpandFilter,
        // 功能组件
        ElSelectTree,
        ElImageViewer,
    },
    data() {
        return {
            hasPermission,
            dialogMov: {
                title: "视频预览",
                dialogVisible: false,
                width: "520px",
            },
            listQuery: {
                studentId: "",
                studentId: "",
                assessDateStart: "",
                assessDateEnd: "",
                dataScope: "",
            },
             // 头部筛选
             formData: {
                btnFormType: true,
                data: [
                    {
                        type: "slot",
                        slotName: "datePicker",
                        key: "dateArr",
                        value: [],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        // auth: ["schoolLeaveRecordAnalysis:record:list"],
                    },
                ],
            },
            videoMovSrc: "",
            ruleList: [],
            schoolCalendar: [],
            stuTotal: "0",
            dialogTableList: [],
            showViewer: false,
            viewPhoto: "",
            moralmanageClassManageStuDataCheckPerson: false, // 控制弹窗是否展示检查人
        };
    },
    computed: {
        ...mapState({
            userName: (state) => state.userName,
            userId: (state) => state.userId,
            schoolId: (state) => state.schoolId,
            permissions: state => state.permissions,
        }),
        exportUrl() {
            return require("@/assets/images/moralAsstessment/summary-icon.png");
        },
        action() {
            return "/api/school/common/uploadFile";
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
    },
    created() {
        this.init();
    },
    activated() {
        this.init();
    },
    methods: {
        async init() {
            this.initTableConfig();
            await this.getSchoolCalendar();
            this.setDefaultDate();
            this.getList();
        },
        /**
         * @Description: 检查人权限控制
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-03-20 16:00:52
         */
        initTableConfig() {
            this.moralmanageClassManageStuDataCheckPerson =
                this.permissions.some((item) => {
                    return (
                        "moralmanage:classmanage:studata:checkPerson" == item
                    );
                });
        },
         /**
          * @Description: 如果当前时间在校历时间内，设置默认时间为校历时间，否则设置默认时间为当前时间前7天到当前时间
          * @DoWhat:
          * @UseScenarios:
          * @Attention:
          * @Author: xwn
          * @Date: 2024-03-20 14:28:24
          */
         async getSchoolCalendar() {
            let params = {
                schoolId: this.schoolId,
                currentDate: this.$moment().format("YYYY-MM-DD HH:MM:SS"),
            };
            await this._fet(
                "/school/schoolCalendar/listByCondition",
                params,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.schoolCalendar = res.data.data;
                }
            });
        },
        /**
         * @Description: 设置默认时间
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-03-20 14:12:35
         */
        setDefaultDate() {
            this.listQuery.assessDateStart = this.$moment(new Date())
                .subtract(7, "days")
                .format("YYYY-MM-DD HH:mm");
            this.listQuery.assessDateEnd =
                this.$moment().format("YYYY-MM-DD HH:mm");
            if (this.schoolCalendar.length > 0) {
                // 如果当前时间在校历时间内，设置默认时间为校历时间，否则设置默认时间为当前时间前7天到当前时间
                let {startTime, endTime} = this.schoolCalendar[0];
                startTime = this.formatDate(startTime);
                endTime = this.formatDate(endTime);
                if (
                    this.listQuery.assessDateEnd < endTime &&
                    this.listQuery.assessDateEnd > startTime
                ) {
                    this.listQuery.assessDateStart = startTime.concat(" 00:00");
                }
            }
            this.formData.data.forEach((item) => {
                if (item.key === "dateArr") {
                    item.value = [
                        this.listQuery.assessDateStart,
                        this.listQuery.assessDateEnd,
                    ];
                }
            });
        },
        formatDate(date) {
            return date.replace(/[年月]/g, "-").replace(/[日]/, "");
        },
        // 图片预览
        handleImg(data) {
            this.viewPhoto = data;
            this.showViewer = true;
        },
        // 关闭图片预览
        handleCloseImg() {
            this.showViewer = false;
        },
        //关闭视频预览
        handleCloseVideo() {
            this.$refs.videoElement.pause();
            this.videoMovSrc = "";
            this.dialogMov.dialogVisible = false;
        },
         /**
         * @Description: 查询/重置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-03-09 11:10:40
         */
         clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        if (item.key === "dateArr") {
                            if (item.value && item.value.length > 0) {
                                this.listQuery.assessDateStart = item.value[0];
                                this.listQuery.assessDateEnd = item.value[1];
                            } else {
                                this.listQuery.assessDateStart = "";
                                this.listQuery.assessDateEnd = "";
                            }
                        }
                    });
                    this.getList(1);
                    break;
                default:
                    break;
            }
        },
        getList () {
            console.log(this.basicForm,this.listQuery,'888888888888888888888');
            // this.listQuery.studentId = this.basicForm.studentId;
            // this.listQuery.schoolId = this.schoolId;
            const params = {
                studentId: this.basicForm.id,
                schoolId: this.schoolId,
                assessDateStart: this.listQuery.assessDateStart,
                assessDateEnd: this.listQuery.assessDateEnd,
                dataScope: this.listQuery.dataScope,
            };
            this._fet(
                "/school/schoolMoralStudentDetail/getStuQuantizationDetail",
                params,
            )
                .then((res) => {
                    if (res.data.code === "200") {
                        const data = res.data.data.studentBehaviorList;
                        this.stuTotal = res.data.data.totalScore;
                        data.forEach((item, index) => {
                            this.$set(item, "processingResultsType", 0);
                            this.$set(item, "processingLoading", false);
                            this.$set(item, "processingList", []);
                        });
                        this.dialogTableList = data;
                        this.dialogTableList.forEach(iv=>{
                            if (iv.img) {
                                iv.img = iv.img.split(",");
                            } else {
                                iv.img = []
                            }
                        })
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                })
                .catch((err) => {
                    this.loadingTable = false;
                });
        },
        /**
         * @Description: 预览视频
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-03-04 15:55:59
         */
        handlePreview(data) {
            console.log("预览视频", data);
            if (data.toLowerCase().indexOf("mov") > -1) {
                this.videoMovSrc = data;
                this.dialogMov.dialogVisible = true;
            } else {
                let videoType = [
                    "swf",
                    "avi",
                    "flv",
                    "mpg",
                    "rm",
                    "mov",
                    "wav",
                    "asf",
                    "3gp",
                    "mkv",
                    "rmvb",
                    "mp4",
                ];
                let url = data.split("/");
                url = url[url.length - 1].split(".");
                url = url[url.length - 1];

                if (
                    data.indexOf("minio") !== -1 &&
                    videoType.indexOf(url) == -1
                ) {
                    let checkFilePreview = filePreview();
                    checkFilePreview.openNewPage(data);
                } else {
                    let url = decodeURIComponent(data); // 要预览视频文件的访问地址
                    let Base64 = require("js-base64").Base64;
                    let previewUrl = `http://124.71.235.241:8012/onlinePreview?url=${encodeURIComponent(
                        Base64.encode(url),
                    )}`;
                    window.open(previewUrl);
                }
            }
        },
        //处理结果查看收起
        toogleExpand(row) {
            let processingResultsType = row.processingResultsType === 1 ? 0 : 1;
            this.$set(row, "processingResultsType", processingResultsType);
            console.log(row,'2222222222222222222222222222222');

            let popUpTable = this.$refs.popUpTable;
            popUpTable.toggleRowExpansion(row);
            // // this.$emit("toogleExpand", row);
            console.log(row.processingResultsType, "--row");
            if (row.processingResultsType == "1") {
                this.setDialogTableList(true, row);
                this.getProcessingRecordListQuery(row);
            }
        },
        setDialogTableList(type, row) {
            this.dialogTableList.forEach((item) => {
                if (item.id == row.id) {
                    this.$set(item, "processingLoading", type);
                }
            });
        },
        //获取处理结果列表
        getProcessingRecordListQuery(row) {
            const sManagerEduMoralStuDataModule =
                new SManagerEduMoralStuDataModule();
            sManagerEduMoralStuDataModule
                .processingRecordListQuery({moralId: row.id})
                .then((res) => {
                    if (res.data.code == "200") {
                        this.dialogTableList.forEach((item) => {
                            console.log(item,'111111111111111111');
                            console.log(res.data.data,'333333333333333333');
                            if (row.id == item.id) {
                                this.$set(
                                    item,
                                    "processingList",
                                    res.data.data,
                                );
                            }
                        });

                        console.log(
                            this.dialogTableList,
                            "this.dialogTableList",
                        );
                        this.setDialogTableList(false, row);
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        //下载详情
        exportStu() {
            let stuForm = {
                schoolId: this.schoolId,
                studentId: this.basicForm.id,
                assessDateStart: this.listQuery.assessDateStart,
                assessDateEnd: this.listQuery.assessDateEnd,
                classId: this.basicForm.classId,
                dataScope: this.listQuery.dataScope,
            };
            downloadFile(
                {
                    url: "/school/schoolMoralStudentDetail/stuQuantizationDetailListExport",
                    form: stuForm,
                },
                () => {
                    // this.$message.success("导出成功");
                    this.$message.success("导出成功（暂不支持视频下载）");
                },
                () => {},
            );
        },

    },
};
</script>
<style lang="scss" scoped>
.moral-education-assessment-records {
    height: calc(100vh - 132px);
    background-color: #fff;
    border-radius: 6px;
    padding: 20px;
    width: 920px !important;

    .top_row {
        border-bottom: none;
        height: 40px;
        display: flex;
        padding-left: 10px;
        line-height: 40px;

        .score_img {
            margin-top: 2px;
        }

        .all_score {
            margin-left: 7px;
        }

        .add_score {
            color: #f99d5e;
            margin-left: 4px;
        }

        .reduce_score {
            color: #71ce66;
            margin-left: 4px;
        }
    }

    .img2 {
        width: 60px;
        height: 45px;
        border-radius: 8px;
        cursor: pointer;
        margin-right: 8px;
    }

    .add {
        color: #f99d5e;
    }

    .jian {
        color: #71ce66;
    }

    .btn_option {
        color: #3c7fff;
        cursor: pointer;
    }

    .btn_delet {
        color: #e63a2e;
        cursor: pointer;
    }

    .processing-list {
        background-color: #fcfcfc;
        box-sizing: border-box;
        padding: 11px 20px 17px 24px;
        border-bottom: 1px solid #edf0f2;

        .processing-title {
            display: flex;
            justify-content: space-between;

            .processing-des {
                width: 700px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }

            .processing-del {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #f24949;
                cursor: pointer;
            }
        }

        .processing-info {
            margin-top: 10px;
        }
    }

    .processing-warper .processing-list:last-of-type {
        border: none;
    }

    .empty {
        box-sizing: border-box;
        padding: 0 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #808487;
    }

    .results {
        color: #3c7fff;
        cursor: pointer;
    }

    .no-results {
        color: #808487;
    }
}

// 图片数量等于限制数量时隐藏上传组件
::v-deep.hide {
    .el-upload--avatar-uploader {
        display: none !important;
    }
}


.dialog-form-video {
    ::v-deep .el-dialog {
        width: 700px !important;
        height: 600px !important;
    }
}
.filter_Btn {
    .line {
        width: 1px;
        height: 24px;
        margin: 0 24px;
        border-left:1px dashed #DBDBDB;
        float: left;
        margin-top: 4px;
        vertical-align: bottom;
    }
}
</style>
